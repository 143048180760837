export const mockUser = {
  startMockingUser: async (email: string) => {
    await localStorage.setItem("mocking_user_email", email)
    window.location.href = "/app"
  },
  stopMockingUser: async () => {
    await localStorage.removeItem("mocking_user_email")
    window.location.reload()
  },
  getUserBeingMockedEmail: () => {
    return localStorage.getItem("mocking_user_email")
  },
}
